<template>
    <section>
        <!-- <div class="filter_block">
            <p v-for="(year, i) of yearList" :key="i+'year'" @click="activeDate = year"
              :class="{'display-date': i>=4 && showElemetDate === false, 'active_filter': activeDate === year}">{{year}}</p>
            <p class="filter_button-all" @click="showElemetDate = !showElemetDate">...</p>
        </div> -->
        <transition-group name="fade">
            <div v-for="(item, i) of listPartners" :key="i+'date'" class="block_partner">
                <p class="partner-date">{{ item.date }}</p>
                <div v-for="(element, j) of item.listInfo" :key="j+'info'" class="partner-info">
                    <img :src="element.img" alt="">
                    <div class="info_block">
                        <p>{{element.text}}</p>
                        <download-file :dataFile="{url: element.url, size: element.size, type: element.type}" class="download-file"/>
                    </div>
                </div>
            </div>
        </transition-group>
    </section>
</template>
<script>
import DownloadFile from '@/components/DownloadFile'
export default {
  name: 'PartnersDocumentBlock',
  components: {
    'download-file': DownloadFile
  },
  props: {
    dataList: {
      type: Array,
      define: true
    }
  },
  data () {
    return {
      activeDate: this.dataList[0].date.slice(6, 10),
      showElemetDate: false
    }
  },
  computed: {
    // yearList () {
    //   const array = this.dataList
    //   var set = new Set()
    //   array.forEach(item => {
    //     set.add(item.date.slice(6, 10))
    //   })
    //   return set
    // },
    listPartners () {
      // const params = this.activeDate
      // console.log(params)
      // return this.dataList.filter(element => {
      //   return element.date.indexOf(params) > -1
      // })
      return this.dataList
    }
  }
  // methods: {
  //   dateArr (date) {
  //     return (new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }))
  //   }
  // }
}
</script>
<style lang="scss" scoped>
section{
    margin-top: 1.88rem;
}
.block_partner{
    display: block;
}
.partner-date{
    margin-bottom: 1.25rem;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #5E686C;
}
.partner-info{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 1.875rem;
}
img{
    width: 4rem;
    height: 4rem;
    border-radius: 100%;
    object-fit: cover;
}
.block_partner:last-child :last-child{
    margin-bottom: 0;
}
.info_block{
    margin-left: 1.38rem;
    p{
        font-weight: 600;
        font-size: .88rem;
        line-height: 1.38rem;
        color: #1F3245;
    }
}
.download-file{
    margin-top: .97rem;
}
/////////////
.filter_block{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: .125rem;
    border-bottom: .06rem solid #D5D6D9;
    margin-bottom: 2rem;
    p{
        font-size: .75rem;
        line-height: 1.125rem;
        color: #003E78;
        opacity: 0.6;
        margin-right: 1.25rem;
        padding-bottom: 1.25rem;
        cursor: pointer;
    }
}
.active_filter{
    font-weight: 600;
    font-size: .813rem;
    line-height: 1.25rem;
    color: #246CB7;
    border-bottom: .125rem solid #246CB7;
    margin-bottom: -0.07rem;
}
.filter_button-all{
    margin-top: -0.2rem !important;
}
.display-date{
    display: none;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .35s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
@media screen and (max-width: 768px){
    .filter_block{
        margin-bottom: 1.43rem;
    }
    .info_block {
        margin-left: .68rem;
    }
    .partner-info{
        margin-bottom: 1.37rem;
    }
}
@media screen and (max-width: 420px){
    .filter_block{
        margin-bottom: 1.43rem;
        overflow-x: auto;
        flex-wrap: nowrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
         p{
            flex: 0 0 auto;
            padding-bottom: 1.05rem;
            margin-right: 1rem;
            margin-bottom: 0rem;
        }
    }
    .info_block {
        margin-left: 1rem;
    }
    .filter_button-all{ padding-right: .76rem;}
    .filter_block::-webkit-scrollbar { width: 0; height: 0;}
    img {
        width: 3.13rem;
        height: 3.13rem;
    }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .block_partner .partner-info img{
        min-width: 4rem;
        max-width: 4rem;
        min-height: 4rem;
        max-height: 4rem;
    }
}
</style>
