<template>
    <section>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>{{ $store.getters.GET_LANG ? 'Партнеры' : 'Partners' }}</h5>
            <partners-document-block v-if="partnersData.length" :dataList="partnersData"/>
        </div>
    </section>
</template>
<script>
import PartnersDocumentBlock from '@/components/partnersChamber/PartnersDocumentBlock'
import BreadCrumb from '@/components/BreadCrumb'
import uniq from '@/methods/uniq'
export default {
  name: 'PartnersChamber',
  data () {
    return {
      partnersData: []
    }
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Партнеры' : 'Partners'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch('setPartners')
    document.title = 'Партнеры'
  },
  watch: {
    '$store.getters.getPartners' (value) {
      let uniqDate = []
      value.map(item => {
        // new Date(item.document.createDate).toLocaleString('ru', { day: 'numeric', month: 'numeric', year: 'numeric' })
        uniqDate.push(item.signing)
      })
      uniqDate = uniq(uniqDate)
      uniqDate.map(item => {
        const date = new Date(item).toLocaleString('ru', { day: 'numeric', month: 'numeric', year: 'numeric' })
        const listInfo = []
        value.map(obj => {
          if (item === obj.signing) {
            listInfo.push({
              img: `${this.$store.getters.getUrlApi}${obj.flagUrl}`,
              text: obj.documentTitle ? obj.documentTitle : obj.document.name,
              url: `${this.$store.getters.getUrlApi}${obj.document.url}`,
              size: obj.document.size,
              type: obj.document.type
            })
          }
        })
        this.partnersData.push({ date, listInfo })
      })
    }
  },
  components: {
    PartnersDocumentBlock,
    BreadCrumb
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: 61.44rem;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;
}
h5{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .tabs{
        overflow-x: auto;
        flex-wrap: nowrap;
        margin: 1.38rem -0.76rem 1.38rem 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        p{
            flex: 0 0 auto;
            padding-bottom: 1.05rem;
            margin-right: 1rem;
        }
        > .active{
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }
    #tab4{ padding-right: .76rem;}
    .tabs::-webkit-scrollbar { width: 0; height: 0;}
}
</style>
